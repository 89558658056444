<template>
    <div>
        <nav class="tw-text-black tw-w-full" id="kodAppBar">
            <div class="tw-mx-auto tw-px-4 lg:tw-px-8">
                <div class="tw-flex tw-justify-between tw-h-16 lg:tw-h-20">
                    <div class="tw-flex">
                        <div class="tw-flex tw-items-center tw-flex-shrink-0">
                            <router-link :to="{ name: 'Home', params: { locale: appLocale } }">
                                <k-logo class="tw-h-10 sm:tw-h-12 tw-object-left" />
                            </router-link>
                        </div>
                    </div>
                    <div v-if="initialized" class="tw-flex tw-items-center">
                        <div v-if="profile && myCv" class="tw-hidden lg:tw-flex lg:tw-items-center tw-space-x-2">
                            <router-link :to="{ name: 'profile-cv', params: { locale: appLocale } }">
                                <k-button color="secondary" custom-class="tw-py-1 tw-px-4">
                                    <k-icon name="user_edit" size="18" class="tw-mr-2" />
                                    {{ $t('global.actions.edit') }}
                                </k-button>
                            </router-link>

                            <k-button v-if="pdfUrl" color="secondary" custom-class="tw-py-1 tw-px-4" :href="pdfUrl" target="_blank" rel="noopener noreferrer">
                                <k-icon name="download" size="18" class="tw-mr-2" />
                                {{ $t('global.actions.download') }}
                            </k-button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <v-navigation-drawer v-model="menuDrawer" :right="true" app floating temporary :width="424" class="tw-z-20">
            <div class="tw-py-4 tw-px-6">
                <div class="tw-flex tw-items-center tw-justify-between">
                    <h5>{{ $t('navigation.titles.menu') }}</h5>
                    <v-btn icon @click="menuDrawer = false" class="tw-text-black">
                        <k-icon name="close" />
                    </v-btn>
                </div>

                <div v-if="pdfUrl && myCv" class="tw-mt-4">
                    <k-button color="secondary" class="tw-w-full" :href="pdfUrl" target="_blank" rel="noopener noreferrer">
                        <k-icon name="download" size="18" class="tw-mr-2" />
                        {{ $t('global.actions.download') }} .pdf
                    </k-button>
                </div>

                <div class="tw-mt-6 tw-flex tw-flex-col tw-space-y-4" v-if="myCv">
                    <div class="tw-max-w-full tw-h-px tw-bg-gray-300">
                        <!-- Divider -->
                    </div>

                    <router-link :to="{ name: 'profile-cv' }">
                        <k-button color="secondary">
                            {{ $t('global.actions.edit') }}
                        </k-button>
                    </router-link>
                </div>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
import KLogo from "@web/components/core/k-logo";
import {getRouteLocale} from '@web/constants/language'
import KButton from '@web/components/core/k-button'

export default {
    components: {KButton, KLogo },
    props: {
        pdfUrl: {
            type: String
        }
    },
    data() {
        return {
            menuDrawer: false
        };
    },
    computed: {
        appLocale() {
            return getRouteLocale()
        },
        myCv() {
            const { url } = this.$store.state.USER.userCv || {};
            return url && window.location.pathname.split("/").pop() === url;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        initialized() {
            const initialized = !!this.$store.state.AUTH.initialized;

            if (!initialized) {
                return false;
            }

            if (this.user) {
                return !!this.profile;
            }

            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
#kodAppBar {
    & > div:first-child {
        max-width: 64rem;
    }
}

@media print {
    #kodAppBar {
        display: none !important;
        visibility: hidden !important;
    }
}
</style>
