<template>
    <div :style="`height: ${size / 16}rem; width: ${size / 16}rem`">
        <radar v-if="show" :invisible-tooltip="invisibleTooltip" :chartData="chartData" />
    </div>
</template>

<script>
import radar from "./ka-it-radar";
import questionaireCalculator from '../../addons/questionaire_calculator'

export default {
    components: {
        radar
    },
    props: {
        cv: {},
        size: {
            type: [Number, String],
            default: 320
        },
        invisibleTooltip: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            programmerData: null,
            expMapping: {
                "0-1": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5-10": 5,
                "10+": 6
            },
            techCategories: ["Frontend", "Backend", "Database", "Dev/Ops", "Style"],
            chartData: null,
            show: false,
            radarStyle: null,
            graphOptions: {
                chartOptions: {
                    lineWidth: 3,
                    responsive: true
                },
                datasetOptions: {
                    data: [0, 0, 0, 0, 0],
                    lineTension: 0.15,
                    borderColor: "transparent"
                }
            }
        };
    },
    computed: {
        innerWidth() {
            return window.innerWidth;
        },
        rTechs() {
            return this.$store.state.ENUMS.technologies_all || [];
        }
    },
    watch: {
        cv: {
            deep: true,
            handler() {
                this.chartData = this.processCV(this.cv);
            }
        },
        rTechs: {
            immediate: true,
            deep: true,
            handler() {
                this.chartData = this.processCV(this.cv);
            }
        }
    },
    methods: {
        processCV(p) {
            let retval = {
                labels: ["Frontend", "Backend", "Database", "Dev/Ops", "Style"],
                datasets: [],
                options: this.graphOptions.chartOptions,
                totalExperiencePoints: 0,
                experiencePointsCounting: {}
            };


            let pDataset = this.graphOptions.datasetOptions;
            pDataset.data = [0, 0, 0, 0, 0];

            retval.labels.forEach(techCat => {
                retval.experiencePointsCounting[techCat] = [];
                
                
                let programmerObject = {
                    name: "test",
                    email: "test",
                    overalPraxis: 0,
                    technologiesKnown: [],
                    technologiesPraxis: [],
                    isTeamleader: false,
                    isArchitect: false,
                    workedDomain: false,
                    gdpr: {
                        gdprValue: true,
                        newsletterValue: true
                    }
                };

                let techsOfCategory = this.rTechs.filter(tech => tech.radarInfo?.map(e => e.cat).indexOf(techCat) > -1);
                let techsOfCategoryNames = techsOfCategory.map(e => e.name);

                p.experience.forEach(tech => {
                    if(techsOfCategoryNames.indexOf(tech.name) > -1){
                        programmerObject.technologiesKnown.push(tech.name) 
                        programmerObject.technologiesPraxis.push({
                            praxis: this.expMapping[tech.experience],
                            name: tech.name
                        })
                        retval.experiencePointsCounting[techCat].push({
                            techName: tech.name,
                            experience: this.expMapping[tech.experience],
                            points: 0
                        })
                    }
                });


                questionaireCalculator.programmerCalculator.isInputValid(programmerObject);
                questionaireCalculator.programmerCalculator.processInput(programmerObject);
                questionaireCalculator.programmerCalculator.computeWage(programmerObject, true);
                
                const wageMin = 60;
                const wageMax = 260;
                const range = wageMax - wageMin;
                const points = Math.min(Math.max(programmerObject.wage - wageMin, 0) / range, 1) * 100; 

                retval.experiencePointsCounting[techCat].push({
                    techName: 'sum',
                    experience: 6,
                    points: points,
                })
                retval.totalExperiencePoints = points;
                pDataset.data[retval.labels.indexOf(techCat)] = points;

            });
            retval.datasets.push(pDataset);
            return retval;
        }
    },
    mounted() {
        this.chartData = this.processCV(this.cv);
        this.show = true;
    }
};
</script>
