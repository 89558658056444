<template>
    <div class="technologies">
        <div class="break-inside-avoid technologies__section" v-for="(cat, catIndex) in sortedUsedTechCategories" :key="catIndex">
            <span>
                <h5>{{ cat[computedLanguage] }}</h5>

                <div class="tw-mt-4 tech-wrapper">
                    <k-chip v-for="tech in cat.data" :key="tech" appearance="secondary" class="tw-uppercase">
                        {{ tech }}
                    </k-chip>

                    <k-chip v-if="cat.shouldBeHidden" @click="changeCategoryShow(cat.eng, !cat.showAll)" style="cursor: pointer" appearance="secondary">
                        ...
                    </k-chip>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
// PRIDAT ADEKVATNY PLAT PRE ANJ PO CV
import { mapGetters } from "vuex";
import KChip from '@web/components/core/k-chip'

export default {
    components: {KChip},
    props: {
        language: {
            type: String,
            default: "sk"
        },
        technologies: {
            type: Array,
            default: () => []
        },
        isPrint: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showTech: {}
        }
    },
    computed: {
        ...mapGetters("ENUMS", {
            allTechnologiesIndexByName: "allTechnologiesIndexByName"
        }),
        allTechnologyTypes() {
            return this.$store.state.ENUMS.technology_types;
        },
        usedFullTechnologies() {
            const returnValue = [];
            (this.technologies || []).forEach(technologyName => {
                const fullTechnology = this.allTechnologiesIndexByName[technologyName];
                // Known technology
                if (fullTechnology) {
                    returnValue.push(fullTechnology);
                }
                // Custom user-defined technology
                else {
                    returnValue.push({
                        category: "Other",
                        description: "",
                        name: technologyName
                    });
                }
            });

            return returnValue;
        },
        usedTechCategories() {
            const allCategories = (this.usedFullTechnologies || []).map(({ category }) => category);
            const uniqueCategories = [...new Set(allCategories)];
            return uniqueCategories;
        },
        sortedUsedTechCategories() {
            const usedCategories = (this.allTechnologyTypes || []).filter(({ eng }) => this.usedTechCategories.includes(eng));
            const categoriesWithData = usedCategories.map(category => ({
                ...category,
                data: this.getAllUsedTechnologiesInCategory(category.eng)
            }))

            const categories = categoriesWithData.map(category => {
                const shouldBeHidden = category.data.length > 6 && !this.isPrint
                const shouldShow = category.eng in this.showTech ? this.showTech[category.eng] : !shouldBeHidden

                return {
                    ...category,
                    data: shouldShow ? category.data : category.data.slice(0, 6),
                    shouldBeHidden,
                    showAll: shouldShow
                }
            })

            return categories
        },
        computedLanguage() {
            if (this.language) {
                if (this.language == "sk" || this.language == "svk") {
                    return "svk";
                } else if (this.language == "en" || this.language == "eng") {
                    return "eng";
                }
            }
            return "svk";
        }
    },
    methods: {
        getAllUsedTechnologiesInCategory(category) {
            return this.usedFullTechnologies.filter(technology => technology.category == category).map(technology => technology.name);
        },
        changeCategoryShow(category, value) {
            this.showTech = {
                ...this.showTech,
                [category]: value
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.technologies__section {
    @media print {
        width: 100%;
        margin-top: 1.5rem;

        &:first-child {
            margin-top: 0;
        }
    }
}

.technologies {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    gap: 1.5rem;

    @include lg {
        grid-template-columns: 1fr 1fr;
    }

    @media print {
        display: block;
    }

    h5 {
        font-size: 1.25rem;
        line-height: 1.25;
        color: $koderia-gray-800;
        font-weight: 600;
    }

    .tech-wrapper {
        display: flex;
        flex-wrap: wrap;

        & > *:not(:last-child) {
            margin-right: 0.5rem;
        }

        & > * {
            margin-bottom: 0.5rem;
        }
    }
}
</style>
