/* eslint-disable */
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
const { firestore, functions } = firebase;
import NotFoundError from "./NotFoundError";
import PermissionDeniedError from "./PermissionDeniedError";

/**
 *
 * @param {string} id
 */
async function getCvViewById(id) {
    try {
        const cvViewRef = firestore().doc(`users_cv_view/${id}`);
        const cvViewShapshot = await cvViewRef.get();

        if (!cvViewShapshot.exists) throw new NotFoundError();

        return {
            ...cvViewShapshot.data(),
            id: cvViewShapshot.id
        };
    } catch (error) {
        if (error.code === "permission-denied") {
            throw new PermissionDeniedError();
        } else if (error.code === "not-found") {
            throw new NotFoundError();
        }

        throw error;
    }
}

/**
 *
 * @param {string} url
 * @param {string} [token=null]
 */
async function getCvViewByUrlAndToken(url, token = null) {
    try {
        const cvUrlRef = firestore()
            .collection("users_cv_url")
            .where("url", "==", url)
            .limit(1);
        const cvUrlSnapshot = await cvUrlRef.get();

        if (cvUrlSnapshot.empty) throw new NotFoundError();

        const cvUrlDocument = cvUrlSnapshot.docs[0];
        const idUidPart = cvUrlDocument.id;
        const idTokenPart = token ? "_" + token : "";
        const id = idUidPart + idTokenPart;

        return await getCvViewById(id);
    } catch (error) {
        if (error.code === "permission-denied") {
            throw new PermissionDeniedError();
        } else if (error.code === "not-found") {
            throw new NotFoundError();
        }

        throw error;
    }
}

async function getPrintCvViewByUrlAndToken(url, token = null) {
    const getUserCv = functions().httpsCallable("getUserCv");
    return await getUserCv({
        id: url,
        token: token
    });
}

export { getCvViewById, getCvViewByUrlAndToken, getPrintCvViewByUrlAndToken };
