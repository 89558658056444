<script>
import { Radar, mixins } from "vue-chartjs";

export default {
    extends: Radar,
    mixins: [mixins.reactiveProp],
    props: ["invisibleTooltip", "suggestedMax"],
    data() {
        return {
            gradient: null
        };
    },
    computed: {
        options: function() {
            const self = this;

            let tooltips = {
                enabled: false,
                custom: function(tooltipModel, app = this) {
                    let techCategories = ["Frontend", "Backend", "Database", "Dev/Ops", "Style"];
                    // Tooltip Element
                    var tooltipEl = document.getElementById("chartjs-tooltip");

                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip";
                        tooltipEl.innerHTML = "<table></table>";
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove("above", "below", "no-transform");
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add("no-transform");
                    }
                    // Set Text
                    let dataPoint = tooltipModel.dataPoints[0];
                    let title = {
                        category: techCategories[dataPoint.index],
                        points: 0
                    };
                    let pointsPerCategoryString = "";
                    let tooltipTechnologies = app._data.experiencePointsCounting[title.category].sort(function(a, b) {
                        return b.points - a.points;
                    });

                    tooltipTechnologies.forEach(t => {
                        if (!isNaN(t.points) && title.points + parseInt(t.points.toFixed(0)) < 101) {
                            t.techName === 'sum' ? title.points += parseInt(t.points.toFixed(0)) : pointsPerCategoryString += `<tr><td>${t.techName}</td></tr>`;
                        }
                    });

                    let tooltipHeader = `<thead><tr style="letter-spacing: 0.0625rem;font-size: 1rem; font-family: 'soleil', serif; line-height: 1.3125rem"><th style="text-align: left">${
                        title.category
                    }</th><th style="text-align: right; min-width: ${window.innerWidth < 500 ? "3.125rem" : "9.375rem"}"> ${
                        title.points
                    }</th></tr><tr><td style="height: 1rem"> </td></tr></thead>`;
                    let tooltipContent = `<tbody>` + pointsPerCategoryString + `</tbody>`;

                    tooltipEl.querySelector("table").innerHTML = tooltipHeader + tooltipContent;

                    var position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font

                    tooltipEl.style.opacity = self.invisibleTooltip ? 0 : 1;
                    tooltipEl.style.visibility = self.invisibleTooltip ? "hidden" : "visible";
                    tooltipEl.style.display = self.invisibleTooltip ? "none" : "initial";
                    tooltipEl.style.zIndex = 10;
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
                    tooltipEl.style.fontFamily = "soleil, sans";
                    tooltipEl.style.fontSize = "0.875rem";
                    tooltipEl.style.lineHeight = "1.25rem";
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = window.innerWidth < 500 ? "0.5rem" : "1.5rem";
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.background = "#141a1dcc";
                    tooltipEl.style.borderRadius = "0.375rem";
                    tooltipEl.style.color = "white";
                }
            };
            return {
                responsive: true,
                devicePixelRatio: 2,
                scale: {
                    angleLines: {
                        display: false
                    },
                    ticks: {
                        suggestedMin: -1,
                        suggestedMax: 100,

                        stepSize: 25,

                        fontColor: "#141a1d",
                        fontFamily: "soleil, sans",
                        fontSize: 12,
                        fontStyle: "normal",
                        backdropColor: "white"
                    },
                    gridLines: {
                        color: "#1d1f291a",
                        circular: true
                    },
                    pointLabels: {
                        fontColor: "#141a1d",
                        fontFamily: "soleil, sans",
                        fontSize: 12,
                        fontStyle: "normal"
                    }
                },
                scaleLabel: {
                    fontColor: "#141a1d",
                    fontFamily: "soleil, sans",
                    fontSize: 12,
                    fontStyle: "bold"
                },
                legend: {
                    display: false
                },
                tooltips: tooltips
            };
        }
    },
    mounted() {
        let gradientCenterX = 150;
        let gradientCenterY = 170;
        let gradientRadius = 130;

        this.gradient = this.$refs.canvas
            .getContext("2d")
            .createRadialGradient(gradientCenterX, gradientCenterY, 0, gradientCenterX, gradientCenterY, gradientRadius);

        this.gradient.addColorStop(0, "rgba(244, 208, 63, 0.6)");
        this.gradient.addColorStop(1, "rgba(14, 166, 109, 0.8)");

        this.chartData.datasets[0].backgroundColor = this.gradient;

        if (window.matchMedia("print").matches === true) {
            this.chartData.datasets[0].borderWidth = 1;
            this.chartData.datasets[0].borderColor = "rgba(14, 166, 109, 0.5)";
            this.chartData.datasets[0].pointBorderColor = "transparent";
            this.chartData.datasets[0].pointBackgroundColor = "rgba(14, 166, 109, 0.5)";
        }

        this.renderChart(this.chartData, this.options);
    }
};
</script>
