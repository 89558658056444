<template>
    <div class="container">
        <main class="page">
            <img src="./assets/password.svg" alt="Key Icon" class="page__icon" />
            <h1 class="page__title">{{ $t('resume.subtitles.cv_is_secure') }}</h1>
            <p class="page__description">
                {{ $t('resume.descriptions.private_access') }}
            </p>
            <form @submit.prevent="submit" class="form">
                <label for="token" class="form__label">{{ $t('global.inputs.password') }}</label>
                <k-input id="token" type="token" v-model="token" class="form__input" autofocus />
                <k-button type="submit" color="primary" class="form__submit">{{ $t('global.actions.unlock') }}</k-button>
            </form>
            <div class="page__links">
                <router-link :to="{ name: 'Home' }" class="link">Koderia.sk</router-link>
                <router-link :to="{ name: 'TermsAndConditions' }" class="link link--terms">
                    {{ $t('navigation.terms_conditions') }}
                </router-link>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: null
        };
    },
    methods: {
        submit() {
            const { origin, pathname } = window.location;
            window.location.replace(`${origin}${pathname}?token=${this.token}`);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.container {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page {
    box-sizing: content-box;
    padding: 1rem;
    width: 100%;
    @include md {
        max-width: 30rem;
    }
}

.page__icon {
    @include -md {
        display: none;
    }
}

.page__title {
    font-size: 2rem;
    line-height: 2.625rem;
    border-bottom: 0.063rem solid $kod-hr;
    padding-bottom: 1rem;
    margin-top: 2rem;
}

.page__description {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 2rem;
}

.form {
    margin-top: 2rem;
}

.form__label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
}
.form__input {
    margin-top: 0.5rem;

    ::v-deep .v-input__control .v-input__slot {
        background-color: $koderia-white !important;
    }
}

.form__submit {
    margin-top: 3rem;
}

.page__links {
    display: inline-block;
    margin-top: 3rem;
}

.link {
    color: $kod-grey;
}

.link:hover {
    color: $kod-black;
}

.link--terms {
    margin-left: 1rem;
}
</style>
