<template>
    <div class="description">{{ data.language }} &ndash; {{ translateLevel(data.level, cvLanguage) }}</div>
</template>

<script>
import { LANGUAGE_LEVELS } from "./../user/constant";

export default {
    props: ["data", "cvLanguage"],
    methods: {
        translateLevel(key, lang = "sk") {
            return LANGUAGE_LEVELS[lang][key] || key;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.description {
    font-size: 0.875rem;
    line-height: 1.75rem;
}
</style>
